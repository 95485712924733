<template>
  <div class="memeber_box pointer">
    <!-- 公司或者剧组的右侧 -->
    <div class="card_company">
      <!-- 剧组信息  遇到的问题 如果我当前是公司的身份 而剧组是我下面的 -->
      <!-- 或者我当前是个个人 没有剧组怎么办 -->
      <!-- <div class="member">
        <img :src="userInfo.avatar" alt="">
        <div class="member_info flex_column_js_sa">
          <div class="member_name">
            {{userInfo.real_name}}
          </div>
          <div class="flex_aling_center">

            <i class="el-icon-phone-outline"></i>
            {{userInfo.phone}}
          </div>
        </div>
      </div> -->
      <div class="member_num flex_aling_center ">

        <div class="member_num_i flex_column_aling_center">
          <div class="num">
            {{talkdata.collection_count || 0}}
          </div>
          <div>
            感兴趣
          </div>
        </div>

        <div class="member_num_i flex_column_aling_center">
          <div class="num">
            {{talkdata.block_count || 0}}
          </div>
          <div>
            拉黑
          </div>
        </div>

      </div>
      <div class="member_line  marinT10 flex_js_sb">
        <div class="member_line_item flex_aling_center">
          <img src="@/assets/image/mine/l1.png" alt="">

          <div class="flex_column_js_sa " @click="jumpTC(2)">
            <div class="member_title">置顶角色</div>
            <div class="member_text">增加更多曝光度</div>
          </div>
        </div>
        <div class="member_line_item flex_aling_center" style="background:#F5F9FF">
          <img src="@/assets/image/mine/l2.png" alt="">

          <div class="flex_column_js_sa " @click="PublishRole('/PostPosition')">
            <div class="member_title">发布角色</div>
            <div class="member_text">更好的时效管理</div>
          </div>
        </div>
      </div>
      <!-- 关注的公司 -->
      <div class="option flex_js_sb flex_aling_center " @click="jump('/CrewManagge')">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          剧组管理
        </div>
        <div class="right">

          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 公司    对应移动端的角色管理-->
      <div class="option flex_js_sb flex_aling_center " @click="jump('/positionManage')">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          角色管理
        </div>
        <div class="right">

          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- v-if="userInfo.company_status != 3" -->
      <div class="option flex_js_sb flex_aling_center " @click="jump('/record')">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          演员模卡管理
        </div>
        <div class="right">

          <i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <!-- v-if="userInfo.company_status != 3" -->
      <div class="option flex_js_sb flex_aling_center " @click="jump('/record')">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          我的收藏
        </div>
        <div class="right">

          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- v-if="userInfo.company_status != 3" -->
      <div v-if="userInfo.company_status == 3 ||  userInfo.company_status == 5" class="option flex_js_sb flex_aling_center " @click="jump('/accountManage')">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          剧组子账号管理
        </div>
        <div class="right">

          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- v-if="authType==2&&userInfo.company_status!=3" crew_id 不需要了   -->
      <div class="option flex_js_sb flex_aling_center " @click="dialogVisibleNum=true" v-if="authType==2&&userInfo.company_status!=3">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          绑定公司账号
        </div>
        <div class="right">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 公司人员管理 -->
      <div class="option flex_js_sb flex_aling_center " v-if="userInfo.company_status != 6 && userInfo.company_status != 5" @click="jump('/CompanyPersonnel')">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          公司人员管理
        </div>
        <div class="right">

          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 只有管理员展示这个 -->
      <div class="option flex_js_sb flex_aling_center " v-if="authType==2&&userInfo.company_status==3" @click="dialogVisibleAdmin=true">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          转移管理员
        </div>
        <div class="right">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 邀请演员经纪人 -->
      <div class="option flex_js_sb flex_aling_center " v-if="authType==2&&userInfo.company_status==3" @click="createShare">
        <div class="left flex_aling_center">
          <img src="@/assets/image/mine/l4.png" alt="">
          邀请演员经纪人
        </div>
        <div class="right">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="card_company">
      <!-- &&userInfo.company_status !=5 && userInfo.company_status!=6 -->
      <div class="setting flex_aling_center pointer" @click="jump('/companyInfo') " v-if="authType==2">
        <i class="el-icon-setting"></i>
        账户设置
      </div>
      <!-- 人员信息 -->
      <div class="member">
        <img :src="userInfo.avatar" alt="">
        <div class="member_info flex_column_js_sa">
          <div class="member_name">
            {{userInfo.real_name}}
          </div>
          <div class="flex_aling_center">

            <i class="el-icon-phone-outline"></i>
            {{userInfo.phone}}
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="绑定公司账号" :visible.sync="dialogVisibleNum" width="28%">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-refresh"></i>
          绑定公司账号
        </div>
      </div>
      <el-form>
        <el-form-item label="邀请码">
          <el-input v-model="num" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div class="recruit_btn_tip">
        注:请联系剧组人员获取剧组管理邀请码
      </div>
      <div class="recruit_btn" @click="saveShare">
        确定
      </div>
    </el-dialog>
    <el-dialog title="管理员转移" :visible.sync="dialogVisibleAdmin" width="28%" v-if="userInfo.company_status==3">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-refresh"></i>
          管理员转移
        </div>
      </div>
      <el-form>
        <el-form-item label="手机号">
          <el-input v-model="phone" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div class="recruit_btn" @click="savePhone">
        确定
      </div>
    </el-dialog>
    <!-- 选择剧组 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="40%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-refresh"></i>
          {{ crew_name }}邀请码
        </div>
      </div>
      <div class="qrcode_box">
        <div class="qrcode_box_tip">
          小程序扫码加入
        </div>
        <img v-if="qrurl" class="qrcode" :src="qrurl" alt="">
        <div class="qrcode_box_tip">
          网页端输入邀请码加入
        </div>
        <div class="share_num flex_aling_center">
          {{num}}
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import QRCode from "qrcode";
import { isAuth } from "@/utils/index";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  talk_data,
  member_note,
  save_usetime,
  del_usetime,
  del_note_pdf,
  save_note_pdf,
  uploadIndex,
  use_crew_code,
  transfer_admin,
  insert_crew_code,
} from "@/utils/network";

export default {
  data() {
    return {
      talkdata: {},
      info: {},
      dialogVisible: false,
      dialogVisiblePDF: false,
      dialogVisibleNum: false,
      dialogVisibleAdmin: false,
      form: [],
      file: {},
      phone: "",
      pdfsrc: "",
      num: "",
      crew_name: "",
      pdflist: [],
      qrurl: "",
      noteAddtimer: null,
    };
  },
  created() {
    console.log(this.userInfo.company_status, "userInfo.company_status");

    this.gettalk_data();
  },
  computed: {
    ...mapState(["company", "authType", "userInfo", "isRew"]),
  },
  methods: {
    ...mapMutations(["clearState"]),
    ...mapActions(["getmember_info"]),
    async qrcode(time) {
      this.qrurl = await QRCode.toDataURL(this.num);
      // console.log(`https://lessonapi.easeyisi.com/mendian/qrcode?path=/subpackage/shopinto/shopinto&mendian_id=${this.formRow.id}&time=${time}`
      // );
    },
    //  邀请演员经纪人
    async createShare(item) {
      let data = {
        company_id: this.company.company.id,
        crew_id: 0,
        // 管理员邀请    1邀请成为剧务  剧务  2邀请成为剧组管理员
        type: 1,
      };
      const res = await insert_crew_code(data);
      this.num = res.data;
      this.crew_name = item.crew_name;
      this.qrcode();
      this.dialogVisible = true;
    },
    // 发布角色
    PublishRole(path) {
      // // 如果是公司
      // if (this.userInfo.company_status == 3) {
      //   this.$message({
      //     message: "暂不能进行操作",
      //     type: "error",
      //   });
      //   return;
      // }

      this.$router.push(path);
    },

    savePhone() {
      this.$confirm("此操作不可逆, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let data = {
            company_id: this.company.company.id,
            to_member_phone: phone,
          };
          const res = await transfer_admin(data);
          if (res.code == 200) {
            this.$message({
              message: "操作成功,请重新登录",
              type: "success",
            });
            localStorage.clear();
            this.clearState();
            this.dialogVisibleAdmin = false;
            if (this.$route.path != "/") {
              this.$router.replace("/");
            }

            location.reload();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 激活邀请码
    async saveShare() {
      let data = {
        code: this.num,
      };
      const res = await use_crew_code(data);
      if (res.code == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        window.location.reload();
        this.getmember_info();
      }
    },
    // 购买套餐类型
    jumpTC(index) {
      // 如果是剧组管理员 不能操作
      if (this.userInfo.company_status == 6) {
        this.$message({
          message: "暂不能进行操作",
          type: "error",
        });
        return;
      }
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }

      this.$router.push({ path: "/PackageBuy", query: { type: index } });
    },
    toggle(index) {
      this.$emit("toggle", index);
    },
    opendialogVisible() {
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }
      this.dialogVisible = true;
    },
    // 跳转演员模卡编辑
    jump(path) {
      if (
        !this.$store.state.CertificationStatus &&
        path != "/accountOptions" &&
        path != "/companyInfo"
      ) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }
      // 如果当前的身份是公司管理

      // if (this.userInfo.company_status == 3) {

      //   if (
      //     path == "/companyInfo" ||
      //     path == "/CrewManagge" ||
      //     path == "/positionManage"
      //   ) {
      //     this.$router.push(path);
      //   } else {
      //     this.$message({
      //       message: "身份权限不足",
      //       type: "error",
      //     });
      //     return;
      //   }
      // }

      this.$router.push(path);
    },
    async onFileChange(e) {
      let file = e.target.files[0];
      if (file) {
        var data = new FormData();
        data.append("file", file);
        const res = await uploadIndex(data);
        let pdf = "https://performapi.90028.cn" + res.data;

        save_note_pdf({ pdf }).then((uploadRes) => {
          console.log(uploadRes);
          if (uploadRes.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
      }
    },
    // 上传
    uploadPdf() {
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }
      var input = document.getElementById("fileInput");
      input.click();
    },
    // 删除附件演员模卡
    delPDF() {
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await del_note_pdf({ id: this.pdflist[0].id });
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    // 下载预览
    pdf() {
      if (this.pdflist.length > 0) {
        this.pdfsrc = this.pdflist[0].pdf;
        window.open(this.pdfsrc);
      }
    },
    // 个人中心数据 感兴趣什么的
    async gettalk_data() {
      let data = {};
      if (this.authType == 2) {
        data.company_member_id = this.company.member.member_id;
      }
      const res = await talk_data(data);
      this.talkdata = res.data;
    },

    async onDayClick(e) {
      let data = {
        day_time: e.date,
      };
      let res;
      if (this.form.includes(e.date)) {
        res = await del_usetime(data);
      } else {
        res = await save_usetime(data);
      }
      if (res.code == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      }
      this.getinfo();
      // e.date
    },
  },
};
</script>

<style lang="scss" scoped>
.memeber_box {
  position: relative;

  .setting {
    position: absolute;
    top: 10px;
    right: 5px;

    font-size: 14px;
    color: #666666;
    .el-icon-setting {
      margin-right: 2px;
    }
  }
  .card_company {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
  }
}
.member {
  // margin-top: 23px;
  display: flex;

  img {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .member_info {
    font-size: 13px;
    color: #666666;
    .member_name {
      font-size: 20px;
      font-weight: 700;
      color: #333333;
    }
  }
}
.member_num {
  margin-top: 27px;
  justify-content: space-around;
  .member_num_i {
    font-size: 14px;
    color: #666666;
    .num {
      font-weight: bold;
      font-size: 20px;
      color: #333333;
    }
  }
}
.member_line {
  width: 100%;
  .member_line_item {
    padding: 0 3px;
    width: 47%;
    height: 63px;
    background: #fff6f5;
    border-radius: 9px;
    img {
      width: 28.8px;
      height: 28.7px;

      margin-right: 5px;
    }
    .member_title {
      font-weight: 500;
      font-size: 12px;
      color: #512525;
    }
    .member_text {
      font-size: 9px;
      color: #999999;
    }
  }
}
.notes {
  padding: 0 10px;
  background: #fbfbfb;
  border-radius: 6px;
  height: 75px;
  border: 1px solid #ecebeb;
  position: relative;
  .notes_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 28px;
    height: 18px;
    background: #fbfbfb;
    border-radius: 4px;
    border: 1px solid #f43b48;

    font-size: 8px;
    color: #f43b48;
  }
  img {
    width: 28.8px;
    height: 28.7px;
    margin-right: 10px;
  }
  .notes_title {
    font-weight: 500;
    font-size: 15px;
    color: #333333;
  }
  .notes_text {
    font-weight: 500;
    font-size: 12px;
    color: #999999;
  }
  .timer {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #ecebeb;
    font-size: 12px;
    color: #999999;
  }
}
.option {
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  height: 60px;
  img {
    width: 21px;
    height: 17px;
    margin-right: 20px;
  }
  .right {
    font-weight: 500;
    font-size: 15px;
    color: #999999;
  }
}
.option_line {
  width: 314px;
  height: 1px;
  background: #f2f4f7;
}
.marginT40 {
  margin-top: 40px;
}
.marginT20 {
  margin-top: 20px;
}

.Calendar_setting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .Calendar_setting_item {
    // width: 48%;
  }
}
.setting_dangqi {
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  span {
    font-weight: 500;
    font-size: 15px;
    color: #ff9b0c;
  }
  .el-icon-s-help {
    font-size: 16p;
    color: #ff9b0c;
    margin-right: 5px;
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
}
.recruit_btn {
  margin: 10px auto;
  width: 150px;
  height: 48px;
  background: linear-gradient(250deg, #ef3c24, #ef7a24);
  border-radius: 11px;
}
#fileInput {
  display: none;
}

::v-deep .el-input__inner {
  width: 370px;
  height: 44px;
  background: #f8f8f8;
  border-radius: 8px;
  border: none !important;
}
.gongsi {
  width: 15px !important;
  height: 15px !important;
  margin-right: 3px !important;
}

// 邀请二维码
.qrcode_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .qrcode_box_tip {
    // width: 50%;
    // text-align: left;
    margin: 20px;
  }
}
.qrcode {
  margin-bottom: 10px;
  width: 300px;
  height: 300px;
}
.share_num {
  width: 408px;
  height: 121px;
  background: #f3f4f4;
  border-radius: 24px;
  justify-content: space-around;

  font-weight: 600;
  font-size: 45px;
  color: #333333;
}
.recruit_btn_tip {
  color: red;
}
</style>
