<template>
  <!-- 新的岗位招聘 只用在首页 -->
  <div class="ecruitment  flex" :class="{'classBorder':isBorder}">
    <div class="ecruitment_new_left" @click="jump(item)">
      <div class="ecruitment_info flex">
        <img class="ecruitment_img" :src="item.crew_photo" alt="">
        <div class="ecruitment_info_right">
          <div class="ecruitment_name  flex_aling_center">
            <span> {{item.crew_name}}</span>
            <div class="ecruitment_type">{{item.type}}</div>
          </div>
          <div class="ecruitment_tag flex_aling_center">
            <div class="ecruitment_tag_item flex_aling_justify_center">
              开机时间：{{item.begin_time}}~{{item.end_time}}
            </div>
            <!-- <div class="ecruitment_tag_item flex_aling_justify_center">
              拍摄地点: {{item.province}}{{item.city}}
            </div> -->
          </div>
          <div class="ecruitment_text  flex_aling_center">
            <div class="ecruitment_text_label">
              风格类型：
            </div>
            {{item.style_type}}
          </div>
          <div class="ecruitment_text  flex_aling_center">
            <div class="ecruitment_text_label">
              拍摄导演：
            </div>
            {{item.director}}
          </div>
        </div>
      </div>
      <div class="ecruitment_c  marinT10   tow_ellipsis">
        故事概况：{{item.story || '暂无故事概况'}}
      </div>
      <div class="line">
      </div>
      <div class="ecruitment_company  marinT10  flex_aling_center">
        <img :src="item.company_logo" alt="">
        {{item.company_name}}
        <div class="ecruitment_company_name flex_aling_center">
          <img src="@/assets/image/image/gongsi.png" alt="">
          {{item.nature_name}}
          {{item.scale_name}}
        </div>
      </div>
    </div>
    <div class="ecruitment_new_right" v-if="item.two_job.length>0">
      <div class="ecruitment_new_right_item" @click="Details(value,item)" v-for="(value,valueIndex) in item.two_job " :key="valueIndex">
        <div class="shixi flex_aling_justify_center " v-if="value.is_through==1">
          需要试戏
        </div>
        <div class="ecruitment_new_right_item_title flex_aling_center">
          {{value.movie_name}}
          <!-- v-if="value.movie_price_mian==2" -->
          <span>
            <!-- 演员佣金 -->
            {{value.movie_begin_price}}k~{{value.movie_end_price}}k
          </span>
        </div>
        <div class="ecruitment_new_right_item_line">
          <span class="ecruitment_new_right_item_label">角色属性： </span>{{value.movie_personattr}}
        </div>
        <!-- <div class="ecruitment_new_right_item_line">
          <span class="ecruitment_new_right_item_label">台词量级：</span>
          {{value.movie_personattr}}
        </div> -->
        <div class="ecruitment_new_right_item_line">
          <span class="ecruitment_new_right_item_label">角色性别：</span>
          {{value.need_sex ==1 ? '女' :'男'}}
        </div>
        <div class="ecruitment_new_right_item_line">
          <span class="ecruitment_new_right_item_label">试戏年龄：</span>
          {{value.need_age_begin}}~ {{value.need_age_end}}
        </div>
        <div class="ecruitment_new_right_item_line">
          <span class="ecruitment_new_right_item_label" style="color:#f43a47" @click.stop="open(value)">查看剧组需要档期</span>

        </div>

      </div>
      <div class="ecruitment_new_right_item ecruitment_new_right_item-zhanwei" v-if="item.two_job.length==1">

      </div>
    </div>

    <!-- 查看对应的档期 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="35%" @close="dialogVisible=false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-date"></i>
          查看档期
        </div>
      </div>
      <div class="Calendar_setting  flex">
        <div class="Calendar_setting_item">
          <!-- :isSwitch="false"  控制翻页 -->
          <Calendar isRed :open="false" :textIsShow="false" :markDays="form"></Calendar>
        </div>
        <!-- <div class="Calendar_setting_item">
          <Calendar isRed :open="false" :isSwitch="false" :markDays="form" :addNum="1" :textIsShow="false"></Calendar>
        </div> -->
      </div>
      <div class="setting_dangqi flex_aling_center">
        <!-- 档期状况： <i class="el-icon-s-help"></i> <span> {{$isKX(form)}} </span> -->
      </div>

    </el-dialog>
  </div>
</template>

<script >
export default {
  props: {
    isBorder: {
      type: Boolean,
      require: false,
      default: false,
    },
    item: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: [],
    };
  },
  methods: {
    // 职位详情
    Details(value, item) {
      value.company_member_id = item.company_member_id;
      // console.log(value,'value');

      this.$router.push({
        path: "/positionDetails",
        query: { item: JSON.stringify(value) },
      });
    },
    open(value) {
      this.form = value.need_time.map((item) => item.need_time);
      this.dialogVisible = true;
    },
    jump(item) {
      let member_id = localStorage.getItem("member_id") || null;
      if (!member_id) {
        this.$message({
          message: "请先登录",
          type: "error",
        });
        return;
      }
      this.$router.push({
        path: "/crewDetails",
        query: { item: JSON.stringify(item) },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ecruitment {
  padding: 15px 20px;
  // padding-bottom: 5px;
  width: 100%;
  height: 280px;
  // background: pink;
  background: #fff;
  border-radius: 18px;
  justify-content: space-between;
  .ecruitment_new_left {
    width: 98%;
  }
  .ecruitment_new_right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    .shixi {
      position: absolute;
      right: 0;
      top: 0;
      width: 69px;
      height: 27px;
      background: #f43a47;
      border-radius: 0px 10px 0px 10px;

      font-size: 10px;
      color: #ffffff;
    }
    .ecruitment_new_right_item {
      position: relative;
      display: flex;
      flex-direction: column;

      justify-content: space-around;
      padding: 10px;
      width: 311px;
      height: 48%;
      background: linear-gradient(0deg, #ffffff, #fff3f4);
      border-radius: 10px;
      border: 1px solid #eaeef4;
      .ecruitment_new_right_item_title {
        font-size: 15px;
        color: #333333;
        font-weight: 700;
        span {
          margin-left: 15px;
          font-size: 12px;
          color: #f43a47;
        }
      }
      .ecruitment_new_right_item_line {
        font-size: 13px;
        color: #333;
        .ecruitment_new_right_item_label {
          color: #666666;
        }
      }
    }
    .ecruitment_new_right_item-zhanwei {
      background: rgba(255, 255, 255, 0);
      border: 0;
    }
  }
  .ecruitment_info {
    .ecruitment_img {
      width: 93px;
      height: 129px;
      font-size: 22px;
      color: #333333;
      border-radius: 10px;
      margin-right: 10px;
    }
    .ecruitment_info_right {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .ecruitment_name {
        width: 100%;
        height: 21px;
        font-size: 22px;
        color: #333333;
        div {
          font-size: 18px;
          color: #f43a47;
        }
      }
      .ecruitment_tag {
        .ecruitment_tag_item {
          margin-right: 10px;
          padding: 2px 10px;

          background: #f5f5f5;
          border-radius: 4px;

          font-size: 13px;
          color: #666666;
        }
      }
      .ecruitment_text {
        font-size: 14px;
        color: #000;
        .ecruitment_text_label {
          color: #666666;
        }
      }
    }
  }
  .ecruitment_c {
    min-height: 40px;
    font-weight: 500;
    font-size: 13px;
    color: #666666;
    line-height: 26px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #f4f6f9;
    margin: 10px 0;
  }
  .ecruitment_company {
    img {
      width: 29px;
      height: 28px;
      border-radius: 50%;
      margin-right: 10px;
    }

    font-weight: 500;
    font-size: 13px;
    color: #333333;
  }

  .ecruitment_company_name {
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
    }

    font-size: 13px;
    color: #999999;
  }
}

.classBorder {
  border: 1px solid #eae8e8;
}
.ecruitment_type {
  margin-left: 10px;
  padding: 2px 3px;
  background: #fbe9e6;
  border-radius: 5px;
  font-size: 10px !important;
  color: #f43a47;
}
</style>

