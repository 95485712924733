import HttpRequest from '@/utils/request.js'
// 获取手机号
export function getPhoneSend(data) {
  return HttpRequest.post('/member/getphone', data)
}
// 用户登录
// 登录
export function userLogin(data) {
  return HttpRequest.post('/member/login', data)
}

export function xcx_reg(data) {
  return HttpRequest.post('/member/xcx_reg', data)
}
export function update_show_bind(data) {
  return HttpRequest.post('/member/update_show_bind', data)
}



export function company_detail(data) {
  return HttpRequest.post('/member/company_detail', data)
}
// 获取双方此刻的job_id
export function get_job_id(data) {
  return HttpRequest.post('/member/get_job_id', data)
}

export function update_msg(data) {
  return HttpRequest.post('/member/update_msg', data)
}

// 聊天界面，拉黑
export function block(data) {
  return HttpRequest.post('/member/block', data)
}

// 公司转移超管号
export function transfer_admin(data) {
  return HttpRequest.post('/member/transfer_admin', data)
}





// 过往作品列表
export function past_list(data) {
  return HttpRequest.post('/member/past_list', data)
}

// 过往作品删除
export function del_past(data) {
  return HttpRequest.post('/member/del_past', data)
}

// 过往作品新增修改
export function save_past(data) {
  return HttpRequest.post('/member/save_past', data)
}



// 企业端- 删除剧务以及公司人员
export function del_company_member(data) {
  return HttpRequest.post('/member/del_company_member', data)
}

// 超管查看公司所有人的信息
export function company_member(data) {
  return HttpRequest.post('/member/company_member', data)
}







// 修改手机号
export function update_phone(data) {
  return HttpRequest.post('/member/update_phone', data)
}



// 待客取药提交接口
export function add_quyao(data) {
  return HttpRequest.post('/member/add_quyao', data)
}


// 三合一医生列表
export function consult_list(data) {
  return HttpRequest.post('/member/consult_list', data)
}

// 三合一下单
export function add_consultorder(data) {
  return HttpRequest.post('/member/add_consultorder', data)
}

// 会诊医生订单列表
export function consult_orderlist(data) {
  return HttpRequest.post('/member/consult_orderlist', data)
}

// 会诊医生开始服务
export function start_consultorder(data) {
  return HttpRequest.post('/member/start_consultorder', data)
}

// 会诊医生订单退款
export function consult_refund(data) {
  return HttpRequest.post('/member/consult_refund', data)
}
// 会诊医生结束服务  只有医生才能点结束服务
export function end_consultorder(data) {
  return HttpRequest.post('/member/end_consultorder', data)
}

// 忘记密码重置
export function forget_password(data) {
  return HttpRequest.post('/member/forget_password', data)
}
// 获取用户签名信息
export function get_tencent_sign(data) {
  return HttpRequest.post('/member/get_tencent_sign', data)
}

// 自己的就诊人列表
export function info_list(data) {
  return HttpRequest.post('/member/info_list', data)
}

// 新增/修改就诊人
export function add_info(data) {
  return HttpRequest.post('/member/add_info', data)
}

// 删除就诊人信息
export function del_info(data) {
  return HttpRequest.post('/member/del_info', data)
}

// 社区医生接单
export function communtiy_getorder(data) {
  return HttpRequest.post('/member/communtiy_getorder', data)
}
// 社区医生开始服务
export function start_getorder(data) {
  return HttpRequest.post('/member/start_getorder', data)
}
// 云医生开始服务
export function start_yunorder(data) {
  return HttpRequest.post('/member/start_yunorder', data)
}
// 修改邮箱
export function update_email(data) {
  return HttpRequest.post('/member/update_email', data)
}

// 企业端- 企业上传企业环境
export function set_company_photo(data) {
  return HttpRequest.post('/member/set_company_photo', data)
}

// 新岗位列表，仅首页使用
export function job_new_list(data) {
  return HttpRequest.post('/member/job_new_list', data)
}





// 社区医生结束服务
export function end_getorder(data) {
  return HttpRequest.post('/member/end_getorder', data)
}

// 云医生结束服务
export function end_yunorder(data) {
  return HttpRequest.post('/member/end_yunorder', data)
}

// 社区医生结束服务
export function getaddress(data) {
  return HttpRequest.post('/index/address', data)
}
// 企业端- 更新个人资料
export function update_company_member(data) {
  return HttpRequest.post('/member/update_company_member', data)
}
// 公告列表
export function notice_list(data) {
  return HttpRequest.post('/member/notice_list', data)
}
// 修改密码
export function update_password(data) {
  return HttpRequest.post('/member/update_password', data)
}




export function yisay_type(data) {
  return HttpRequest.post('/member/yisay_type', data)
}
// 医说列表
export function yisay_list(data) {
  return HttpRequest.post('/member/yisay_list', data)
}

// 所有的评论列表
export function reply_list(data) {
  return HttpRequest.post('/member/reply_list', data)
}

// 企业端- 企业信息展示
export function company_info(data) {
  return HttpRequest.post('/member/company_info', data)
}

// 企业端- 企业资质认证
export function add_company(data) {
  return HttpRequest.post('/member/add_company', data)
}
// 城市筛选数据
export function city_list(data) {
  return HttpRequest.post('/member/city_list', data)
}




// 企业端- 企业设置地址
export function set_company_map(data) {
  return HttpRequest.post('/member/set_company_map', data)
}
// 企业端- 企业基本资料完善
export function audit_company(data) {
  return HttpRequest.post('/member/audit_company', data)
}

// 企业端- 剧组信息展示
export function crew_index(data) {
  return HttpRequest.post('/member/crew_index', data)
}

// 企业端- 发布岗位
export function create_job(data) {
  return HttpRequest.post('/member/create_job', data)
}

// 企业端- 剧组新增/修改
export function crew_save(data) {
  return HttpRequest.post('/member/crew_save', data)
}

// 企业端- 剧组删除
export function crew_del(data) {
  return HttpRequest.post('/member/crew_del', data)
}

// 企业端- 剧组生成一次性邀请码
export function insert_crew_code(data) {
  return HttpRequest.post('/member/insert_crew_code', data)
}

//企业端- 剧组邀请码激活
export function use_crew_code(data) {
  return HttpRequest.post('/member/use_crew_code', data)
}

//立即沟通调用
export function connect_member(data) {
  return HttpRequest.post('/member/connect_member', data)
}



// 企业端- 剧组人员查看，无分页
export function crew_member(data) {
  return HttpRequest.post('/member/crew_member', data)
}


// 企业端- 删除剧组人员
export function del_crew_member(data) {
  return HttpRequest.post('/member/del_crew_member', data)
}





// 发评论
export function reply_forum(data) {
  return HttpRequest.post('/member/reply', data)
}

// 注册
export function userREG(data) {
  return HttpRequest.post('/member/reg', data)
}

// 发送短信
export function sendsms(data) {
  return HttpRequest.post('/member/sendsms', data)
}

// 登录获取用户信息
export function getUser(data) {
  return HttpRequest.post('/member/memberelse', data)
}

// 修改头像和昵称
export function update_info(data) {
  return HttpRequest.post('/member/update_avatar', data)
}
// 商品详情
export function goods_detail(data) {
  return HttpRequest.post('/member/goods_detail', data)
}
// 商品下单报名
export function buy_goods(data) {
  return HttpRequest.post('/member/buy_goods', data)
}

// 聊天列表
export function talk_list(data) {
  return HttpRequest.post('/member/talk_list', data)
}
// 每句发话都调用此接口
export function update_talk(data) {
  return HttpRequest.post('/member/update_talk', data)
}


// 聊天前调一下双方的昵称更新
export function update_name(data) {
  return HttpRequest.post('/member/update_name', data)
}

// 全部的 牛马查看的剧组列表
export function all_crew_index(data) {
  return HttpRequest.post('/member/all_crew_index', data)
}






// 聊天界面，申请获取对方手机号
export function through_job(data) {
  return HttpRequest.post('/member/through_job', data)
}

// 聊天界面，手机号申请同意拒绝
export function audit_phone(data) {
  return HttpRequest.post('/member/audit_phone', data)
}
// 聊天界面，申请试戏/邀请试戏
export function phone_job(data) {
  return HttpRequest.post('/member/phone_job', data)
}

// 聊天界面，试戏同意拒绝
export function audit_through(data) {
  return HttpRequest.post('/member/audit_through', data)
}









// 个人演员模卡新增/修改
export function save_note(data) {
  return HttpRequest.post('/member/save_note', data)
}



// 学历新增/修改
export function save_edu(data) {
  return HttpRequest.post('/member/save_edu', data)
}

// 学历删除
export function del_edu(data) {
  return HttpRequest.post('/member/del_edu', data)
}

// 作品视频新增/修改
export function save_video(data) {
  return HttpRequest.post('/member/save_video', data)
}

// 作品视频删除
export function del_video(data) {
  return HttpRequest.post('/member/del_video', data)
}


// 形象图片新增/修改
export function save_photo(data) {
  return HttpRequest.post('/member/save_photo', data)
}

// 形象图片删除
export function del_photo(data) {
  return HttpRequest.post('/member/del_photo', data)
}

// 数据截图新增/修改
export function save_data(data) {
  return HttpRequest.post('/member/save_data', data)
}
// 数据截图删除
export function del_data(data) {
  return HttpRequest.post('/member/del_data', data)
}

// 代表作新增/修改
export function save_work(data) {
  return HttpRequest.post('/member/save_work', data)
}
// 代表作删除
export function del_work(data) {
  return HttpRequest.post('/member/del_work', data)
}

// 演员模卡附件新增/修改
export function save_note_pdf(data) {
  return HttpRequest.post('/member/save_note_pdf', data)
}

// 演员模卡附件删除
export function del_note_pdf(data) {
  return HttpRequest.post('/member/del_note_pdf', data)
}
// 岗位列表
export function job_list(data) {
  return HttpRequest.post('/member/job_list', data)
}
// 企业端- 投递演员模卡
export function send_note(data) {
  return HttpRequest.post('/member/send_note', data)
}
// 企业性质列表
export function nature(data) {
  return HttpRequest.post('/member/nature', data)
}
// 企业规模列表
export function scale(data) {
  return HttpRequest.post('/member/scale', data)
}
// 行业列表
export function industry(data) {
  return HttpRequest.post('/member/industry', data)
}
//企业端- 收藏岗位
export function coll_job(data) {
  return HttpRequest.post('/member/coll', data)
}
//企业端- 收藏岗位
export function job_detail(data) {
  return HttpRequest.post('/member/job_detail', data)
}
//个人中心数据
export function talk_data(data) {
  return HttpRequest.post('/member/talk_data', data)
}


//待试戏/已试戏列表
export function member_through_list(data) {
  return HttpRequest.post('/member/member_through_list', data)
}





//待试戏/已试戏列表
export function through_list(data) {
  return HttpRequest.post('/member/through_list', data)
}






// 设置身份
export function change_login(data) {
  return HttpRequest.post('/member/change_login', data)
}
// 个人演员模卡
export function member_note(data) {
  return HttpRequest.post('/member/member_note', data)
}
// 档期新增
export function save_usetime(data) {
  return HttpRequest.post('/member/save_usetime', data)
}
// 档期删除
export function del_usetime(data) {
  return HttpRequest.post('/member/del_usetime', data)
}
// 实名认证
export function real_name(data) {
  return HttpRequest.post('/Member/real_name', data)
}

// 增值套餐列表,1是个人可见，234是公司可见
export function vip_lists(data) {
  return HttpRequest.post('/Member/vip_list', data)
}


// 购买会员套餐
export function buy_vip(data) {
  return HttpRequest.post('/Member/buy_vip', data)
}


// 用户预约住院
export function add_order(data) {
  return HttpRequest.post('/Member/add_order', data)
}

// 订单列表
export function getorder_list(data) {
  return HttpRequest.post('/member/order_list', data)
}


// 公司端-沟通过列表
export function company_talk_list(data) {
  return HttpRequest.post('/member/company_talk_list', data)
}

// 公司端-收藏人员列表
export function company_coll_list(data) {
  return HttpRequest.post('/member/company_coll_list', data)
}









// 物流信息，暂时
export function safe_order(data) {
  return HttpRequest.post('/member/safe_order', data)
}

// 意见反馈
export function add_view(data) {
  return HttpRequest.post('/member/add_view', data)
}

// 我的课程
export function my_lesson(data) {
  return HttpRequest.post('/member/my_lesson', data)
}
// 绑定
export function bind_tencent(data) {
  return HttpRequest.post('/member/bind_tencent', data)
}

// 删除9+1
export function del_forum(data) {
  return HttpRequest.post('/member/del_forum', data)
}






// 好友和粉丝列表，仅业务经理和区域经理可见
export function bind_list(data) {
  return HttpRequest.post('/member/bind_tencent', data)
}

// 用户取消订单
export function cancel_order(data) {
  return HttpRequest.post('/member/cancel_order', data)
}

// 用户订单详情
export function order_detail(data) {
  return HttpRequest.post('/mendian/order_detail', data)
}

// 用户终止订单
export function stop_order(data) {
  return HttpRequest.post('/mendian/stop_order', data)
}

// 用户余额明细
export function balance_log(data) {
  return HttpRequest.post('/member/balance_log', data)
}
// 兑换商城列表
export function exchange_list(data) {
  return HttpRequest.post('/member/exchange_list', data)
}
// 兑换商城详情
export function exchange_detail(data) {
  return HttpRequest.post('/member/exchange_detail', data)
}

// 购买书籍
export function buy_exchange(data) {
  return HttpRequest.post('/member/buy_exchange', data)
}
// 商品确认收货
export function receive_goods(data) {
  return HttpRequest.post('/member/receive_goods', data)
}

// 物流信息查询，嵌入商品订单详情页
export function wuliu_info(data) {
  return HttpRequest.post('/member/wuliu_info', data)
}
// 获取订单截图
export function photo_list(data) {
  return HttpRequest.post('/member/photo_list', data)
}
// 社区医生订单退款
export function order_refund(data) {
  return HttpRequest.post('/member/order_refund', data)
}
// 云医生订单退款，status=2可以
export function yunorder_refund(data) {
  return HttpRequest.post('/member/yunorder_refund', data)
}
// 企业端- 岗位删除
export function del_job(data) {
  return HttpRequest.post('/member/del_job', data)
}
//演员列表
export function member_list(data) {
  return HttpRequest.post('/member/member_list', data)
}
// 商家分享海报
export function getorder_lists(data) {
  return HttpRequest.post('/order/share', data)
}
// 云医生开启关闭服务
export function set_open(data) {
  return HttpRequest.post('/member/set_open', data)
}
// 商家支付佣金
export function mendian_pay(data) {
  return HttpRequest.post('/order/mendian_pay', data)
}
// 个人中心
export function gethomeinfo(data) {
  return HttpRequest.post('/member/memberelse', data)
}
// 提现列表
export function withdrawal_list(data) {
  return HttpRequest.post('/member/withdrawal_list', data)
}
// 用户发起提现
export function add_withdrawal(data) {
  return HttpRequest.post('/member/add_withdrawal', data)
}
// 佣金列表
export function commission_list(data) {
  return HttpRequest.post('/member/commission_list', data)
}

// 我的推广
export function agent_list(data) {
  return HttpRequest.post('/member/agent_list', data)
}

// 设置真实姓名和收款码
export function update_code(data) {
  return HttpRequest.post('/member/update_code', data)
}
// 邀请排行榜，前10名已自动排序
export function rank_list(data) {
  return HttpRequest.post('/member/rank_list', data)
}

// 删自己发的评论
export function del_reply(data) {
  return HttpRequest.post('/member/del_reply', data)
}

// 9+1列表
export function activity_list(data) {
  return HttpRequest.post('/member/activity_list', data)
}
// 9+1详情
export function activity_detail(data) {
  return HttpRequest.post('/member/activity_detail', data)
}

// 9+1发帖
export function send_forum(data) {
  return HttpRequest.post('/member/send_forum', data)
}
// 发起9+1
export function add_activity(data) {
  return HttpRequest.post('/member/add_activity', data)
}



// 用户评价云医生
export function ping_yunorder(data) {
  return HttpRequest.post('/member/ping_yunorder', data)
}
// 云医生评价列表
export function doctor_ping_list(data) {
  return HttpRequest.post('/member/doctor_ping_list', data)
}





// 用户上传外卖订单信息
export function update_order(data) {
  return HttpRequest.post('/order/update_order', data)
}


// 机构管理员展示自己的信息
export function show_mendian_user(data) {
  return HttpRequest.post('/mendian/show_mendian_user', data)
}
// 用户退款
export function refund(data) {
  return HttpRequest.post('/order/refund', data)
}
// 评价完整列表
export function ping_list(data) {
  return HttpRequest.post('/mendian/ping_list', data)
}
// 用户评价，只有已完成和已终止才能评价
export function pingjia(data) {
  return HttpRequest.post('/mendian/pingjia', data)
}
// 机构列表
export function mendian_list(data) {
  return HttpRequest.post('/mendian/mendian_list', data)
}
// 机构管理员新增/修改
export function usersave_mendian_user(data) {
  return HttpRequest.post('/user/save_mendian_user', data)
}
// 机构新增/修改
export function usersave_mendian(data) {
  return HttpRequest.post('/user/save_mendian', data)
}
// 机构删除
export function del_mendian(data) {
  return HttpRequest.post('/user/del_mendian', data)
}
// 机构管理员列表
export function mendian_user_list(data) {
  return HttpRequest.post('/user/mendian_user_list', data)
}
// 机构管理员删除
export function del_mendian_user(data) {
  return HttpRequest.post('/user/del_mendian_user', data)
}
// 机构管理员修改自己的资料
export function save_mendian_user_info(data) {
  return HttpRequest.post('/mendian/save_mendian_user_info', data)
}
// 留言板发送消息
export function send_message(data) {
  return HttpRequest.post('/member/send_message', data)
}
// 留言板记录，机构管理员和用户通用
export function message_list(data) {
  return HttpRequest.post('/member/message_list', data)
}
// 商务合作
export function join(data) {
  return HttpRequest.post('/member/join', data)
}
// 我的收藏
export function collection_list(data) {
  return HttpRequest.post('/mendian/collection_list', data)
}
// 收藏/取消收藏
export function set_collection(data) {
  return HttpRequest.post('/mendian/set_collection', data)
}

// 购物车内加减商品
export function update_cart(data) {
  return HttpRequest.post('/member/update_cart', data)
}
// 购物车下单
export function buy_specs(data) {
  return HttpRequest.post('/member/buy_specs', data)
}
// 拜访用户，仅status=1和4可传
export function visit_photo(data) {
  return HttpRequest.post('/member/visit_photo', data)
}
// 社区医生下单
export function add_community(data) {
  return HttpRequest.post('/member/add_community', data)
}

// 云医生列表
export function yun_list(data) {
  return HttpRequest.post('/member/yun_list', data)
}

// 疗效直播列表
export function forum_list(data) {
  return HttpRequest.post('/member/forum_list', data)
}



// 地址列表
export function getAddresslist(data) {
  return HttpRequest.post('/member/address_list', data)
}
// 新增地址列表
export function addAddress(data) {
  return HttpRequest.post('/member/add_address', data)
}
// 修改地址
export function updateAddress(data) {
  return HttpRequest.post('/member/update_address', data)
}
// 刪除地址
export function delAddress(data) {
  return HttpRequest.post('/member/del_address', data)
}
// 课程详情
export function specs_detail(data) {
  return HttpRequest.post('/member/specs_detail', data)
}


// 首页
export function homepage(data) {
  return HttpRequest.post('/member/homepage', data)
}
// 兑换商城加入购物车
export function add_exchange_cart(data) {
  return HttpRequest.post('/member/add_exchange_cart', data)
}
// 视频分类
export function type_list(data) {
  return HttpRequest.post('/member/type_list', data)
}
// banner
export function getbanner_list(data) {
  return HttpRequest.post('/member/banner_list', data)
}
// 好书分享列表
export function duihuanshop_list(data) {
  return HttpRequest.post('/member/shop_list', data)
}

// 问答列表
export function question_list(data) {
  return HttpRequest.post('/member/question_list', data)
}


// 身份认证状态
export function auth_status(data) {
  return HttpRequest.post('/member/auth_status', data)
}




// 分享
export function share(data) {
  return HttpRequest.post('/member/share', data)
}
// 平台内容，只返回一条
export function notice(data) {
  return HttpRequest.post('/member/notice', data)
}
// 资讯列表
export function word_list(data) {
  return HttpRequest.post('/member/word_list', data)
}
// 用户积分明细
export function integral_log(data) {
  return HttpRequest.post('/member/integral_log', data)
}

// 商品加入购物车
export function add_cart(data) {
  return HttpRequest.post('/member/add_cart', data)
}
// 购物车，无分页
export function cart_list(data) {
  return HttpRequest.post('/member/cart_list', data)
}
// 书籍分类
export function book_type_list(data) {
  return HttpRequest.post('/member/book_type_list', data)
}

// 购买课程
export function buy_lesson(data) {
  return HttpRequest.post('/member/buy_lesson', data)
}

// add_yunorder
export function add_yunorder(data) {
  return HttpRequest.post('/member/add_yunorder', data)
}


// 商品列表
export function getgoodslist(data) {
  return HttpRequest.post('/member/specs_list', data)
}
// 下单
export function Payputorder(data) {
  return HttpRequest.post('/Order/putorder', data)
}
// 订单列表
export function getOrder(data) {
  return HttpRequest.post('/Order/index', data)
}

// 订单详情
export function getOrderDetails(data) {
  return HttpRequest.post('/Order/detail', data)
}

// 订单取消
export function orderCancel(data) {
  return HttpRequest.post('/Order/cancel', data)
}

// 平台配置
export function version_info(data) {
  return HttpRequest.post('/member/version', data)
}

// 酒店详情页
export function mendian_detail(data) {
  return HttpRequest.post('/mendian/mendian_detail', data)
}

// 银行卡列表
export function bank_list(data) {
  return HttpRequest.post('/member/bank_list', data)
}

// 银行卡新增
export function add_bankcard(data) {
  return HttpRequest.post('/member/add_bankcard', data)
}
// 银行卡新增
export function update_bankcard(data) {
  return HttpRequest.post('/member/update_bankcard', data)
}

// 银行卡删除
export function del_bankcard(data) {
  return HttpRequest.post('/member/del_bankcard', data)
}

// 用户下单-支付余额
export function pay_order(data) {
  return HttpRequest.post('/member/pay_order', data)
}

// 邀请
export function workerInvite(data) {
  return HttpRequest.post('/member/invite', data)
}

// 上传接口
export function uploadIndex(data) {
  return HttpRequest.post('/index/upload', data)
}

// 实名认证
export function real_member(data) {
  return HttpRequest.post('/member/real_member', data)
}




// 上传接口
export function isdeposit(data) {
  return HttpRequest.post('/order/is_deposit', data)
}


// 上传接口
export function getproperty(data) {
  return HttpRequest.post('/member/property', data)
}


// 优惠券列表
export function coupon_list(data) {
  return HttpRequest.post('/member/coupon_list', data)
}

// 优惠券领取
export function add_coupon(data) {
  return HttpRequest.post('/member/add_coupon', data)
}

// 我的优惠券列
export function my_coupon_list(data) {
  return HttpRequest.post('/member/my_coupon_list', data)
}
// 上传接口
export function setrecover(data) {
  return HttpRequest.post('/member/recover', data)
}
// 商品订单列表
export function exchange_mylist(data) {
  return HttpRequest.post('/member/exchange_mylist', data)
}

// 身份认证提交
export function add_auth(data) {
  return HttpRequest.post('/member/add_auth', data)
}



// 科室列表
export function depart_list(data) {
  return HttpRequest.post('/member/depart_list', data)
}

// 职级列表
export function position_list(data) {
  return HttpRequest.post('/member/position_list', data)
}


// 疾病列表
export function ill_list(data) {
  return HttpRequest.post('/member/ill_list', data)
}





// 商品兑换酒店订单
export function use_goods(data) {
  return HttpRequest.post('/member/use_goods', data)
}

// 聊天界面，所有按钮权限刷新
export function job_auth(data) {
  return HttpRequest.post('/member/job_auth', data)
}


// 回收列表
export function getrecover_list(data) {
  return HttpRequest.post('/worker/recover_list', data)
}


// 提交回收
export function finish_recover(data) {
  return HttpRequest.post('/worker/finish_recover', data)
}


// 提交回收
export function order_finish(data) {
  return HttpRequest.post('/worker/order_finish', data)
}



// 实名认证
export function writeSing(data) {
  return HttpRequest.post('/Member/write', data)
}

// 配送账本
export function send_book(data) {
  return HttpRequest.post('/worker/send_book', data)
}

// 充值套餐列表
export function recharge_list(data) {
  return HttpRequest.post('/mendian/recharge_list', data)
}
// 用户充值
export function recharge(data) {
  return HttpRequest.post('/fuyou/recharge', data)
}

// 用户充值
export function getBankList(data) {
  return HttpRequest.post('/fuyou/recharge', data)
}

// 用户充值
export function guanyuwomen(data) {
  return HttpRequest.post('/fuyou/recharge', data)
}

// 用户端-收藏公司列表
export function coll_company_list(data) {
  return HttpRequest.post('/member/coll_company_list', data)
}

// 用户端-收藏公司/取消收藏公司
export function coll_company(data) {
  return HttpRequest.post('/member/coll_company', data)
}


// 用户端-沟通过列表
export function member_talk_list(data) {
  return HttpRequest.post('/member/member_talk_list', data)
}
// 用户端-投递过列表
export function member_send_list(data) {
  return HttpRequest.post('/member/member_send_list', data)
}

// 用户端-收藏岗位列表
export function member_coll_list(data) {
  return HttpRequest.post('/member/member_coll_list', data)
}
// 企业端- 简历投递列表
export function send_list(data) {
  return HttpRequest.post('/member/send_list', data)
}





